html {
  height: 100%;
}

html body {
  height: 100%;
  margin: 0;
}

html #app {
  flex-direction: column;
  height: 100%;
  display: flex;
}

html #app #main {
  height: 100%;
  overflow-y: auto;
}

html #app #main #login {
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  height: 100%;
  margin: 0 auto;
  display: flex;
}
/*# sourceMappingURL=index.57bcb001.css.map */
