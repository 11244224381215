html {
    height: 100%;
    body {
        margin: 0;
        height: 100%;
    }
    #app {
        display: flex;
        flex-direction: column;
        height: 100%;
        #main {
            height: 100%;
            overflow-y: auto;
            #login {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 auto;
                max-width: 500px;
            }
        }
    }
}
